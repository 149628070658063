/* eslint-disable camelcase */
import React from "react";
import { css } from "@emotion/react";
import { graphql } from "gatsby";
import { CardLink, Grid, Layout } from "~components";
import { breakpoint } from "~utils/css";

/** ============================================================================
 * @component
 * Article graphql wrapper.
 * @param  {data}     object  Data inherited from Storyblok plugin.
 * @param  {location} object  Data inherited Gatsby @reach/router.
 * @return {node}     <main>  Wrapped StoryblokTemplate JSX.
 */
const Products = ({ data, location }) => {
  // ---------------------------------------------------------------------------
  // variables

  const products = !data?.allStoryblokEntry?.edges?.[0]
    ? []
    : data.allStoryblokEntry.edges.map(({ node }) => ({
        ...node,
        content: JSON.parse(node.content)
      }));

  // ---------------------------------------------------------------------------
  // render

  const seo = { seoDescription: ``, seoKeywords: `` };

  return (
    <Layout location={location} seo={seo}>
      <section>
        <Grid>
          <header
            css={css`
              grid-column: span 12 / span 12;
              position: relative;
              padding: 6rem 0 2rem;
              text-transform: uppercase;
            `}
          >
            <h1 className="h1">All Products</h1>
          </header>
        </Grid>
      </section>

      <section
        css={css`
          width: 100%;
          position: relative;
          padding: 3rem 0;
        `}
      >
        {products?.[0] && (
          <Grid>
            {products.map((item) => {
              const itemData = {
                ...item,
                name: item?.content?.name,
                hoverTagline: item?.content.hover_tagline
              };

              if (item?.content?.colours?.[0]) {
                itemData.image = item.content.colours[0].image;
              }

              //

              return (
                <div
                  key={`material-${item?.full_slug}`}
                  css={css`
                    grid-column: span 12 / span 12;
                    margin-bottom: 2rem;

                    ${breakpoint(`large-tablet`)} {
                      grid-column: span 4 / span 4;
                    }
                  `}
                >
                  <CardLink data={itemData} />
                </div>
              );
            })}
          </Grid>
        )}
      </section>
    </Layout>
  );
};

export default Products;

export const query = graphql`
  query Products {
    allStoryblokEntry(filter: { full_slug: { regex: "/products/(.*)/" } }) {
      edges {
        node {
          id
          uuid

          name
          path
          position
          slug
          lang
          group_id
          full_slug
          content
        }
      }
    }
  }
`;
